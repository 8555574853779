@import "main";
@import 'bootstrap.min.css';

.affiliate-button-div {
  margin-bottom: 1rem;
}

.affiliate-info {
  line-height: 0.8;
  padding-top: 0.3rem;
}

.affiliate-card-info {
  max-width: 637px;
}
.affiliate-card, .youtube-card {
  max-width: 637px;
  margin-bottom: 1rem;
}

html { scroll-padding-top: 125px; }

.Header-link {
  font-weight: 600;
}

span.ltrText {
    unicode-bidi: bidi-override;
    direction: rtl;
}


a[href^="http://"]:not([href*="martinvondannen.de"]):after, a[href^="https://"]:not([href*="martinvondannen.de"]):not(.intlink):after {
  content: " " url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-box-arrow-up-right' viewBox='0 0 16 16'> <path fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/> <path fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/></svg>");
    padding-left: 2px;
}

a.no_affiliate_icon:before {
  content: none !important;
}

a.no_hyperlink_icon:after {
  content: none !important;
}

a {
  text-decoration: none;
}

a[rel~=sponsored]:before {
  content: " " url("data:image/svg+xml,<svg width='16' height='16' fill='currentColor' class='bi bi-bag-check' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'> <path fill-rule='evenodd' d='M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z'/> <path d='M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z'/></svg>");
  padding-right: 3px;
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: var(--bs-info);
}

.navbar-nav {
  --bs-nav-link-color: var(--bs-white);
  --bs-nav-link-hover-color: var(--bs-info);
}
